/* Global styles */
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Roboto:400,700&display=swap");

/* Add this to the top of your App.css file */
body::before {
  content: "";
  background-image: url("./background2.jpeg");
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5; /* Adjust the opacity to your liking */
  z-index: -1;
}

/* Update the body style to use a darker color */
body {
  font-family: "Roboto", sans-serif;
  background-color: #1e1e2c;
  color: #f8f9fa;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Theme-specific styles */
.theme-title {
  font-size: 3rem;
}

.theme-subtitle {
  font-size: 1.25rem;
  margin-bottom: 0rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.theme-form {
  background-color: rgba(38, 41, 58, 0.8);
  padding: 2rem;
  border-radius: 3px;
}

.theme-label {
  font-size: 1.1rem;
  font-weight: 700;
}

.theme-input {
  background-color: #3a3d52;
  color: #f8f9fa;
  border-color: #3a3d52;
}

.theme-input::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Lighter text color for the placeholder */
}

.theme-input:focus {
  color: rgba(255, 255, 255, 0.9); /* Lighter text color when the input is focused */
}

.theme-input:focus {
  background-color: #3a3d52;
  border-color: #6c757d;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

.theme-text {
  font-weight: 400;
}

.theme-button {
  background-color: #e63946;
  border: none;
}

.theme-button:hover {
  background-color: #d8343f;
}

.theme-button:focus {
  background-color: #e63946;
  box-shadow: 0 0 0 0.2rem rgba(230, 57, 70, 0.5);
}
